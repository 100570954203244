import { API_DOMAINS, ApiContext } from '../../types';

export interface CancelSingleArgs {
  bookingId: string;
  offeringType: string;
  bookingRevision?: any;
}

export const cancelSingle = async ({
  bookingId,
  offeringType,
  bookingRevision,
  flowAPI,
  authorization,
}: CancelSingleArgs & ApiContext): Promise<number> => {
  const { httpClient } = flowAPI;
  return (
    await httpClient.delete(
      `${
        API_DOMAINS.MEMBER_AREA
      }/site-member/booking/${offeringType}/${bookingId}${getBookingUrlSuffix(
        bookingRevision,
      )}`,
      {
        headers: {
          Authorization: authorization,
        },
      },
    )
  ).status;
};

export const getBookingUrlSuffix = (revision?: any) =>
  revision ? `/${revision}` : '';
