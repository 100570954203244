import { SelectedTab } from '../../types';
import { CreateActionParams } from '../actions';

export type SetSelectedTab = (args: SelectedTab) => Promise<void>;

export function createSetSelectedTabAction({
  getControllerState,
}: CreateActionParams): SetSelectedTab {
  return async (selectedTab) => {
    const [, setState] = getControllerState();

    setState({
      selectedTab,
    });
  };
}
