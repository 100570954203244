import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BiLogger, createBiLogger } from '../bi/biLoggerFactory';
import { Api } from '../../api';

export type ActionsContext = {
  flowAPI: ControllerFlowAPI;
  api: Api;
  biLogger?: BiLogger;
};

export const createActionsContext = ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): ActionsContext => {
  const api = new Api({ flowAPI });
  const biLogger = createBiLogger({ flowAPI, api });

  return {
    flowAPI,
    api,
    biLogger,
  };
};
