import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export type ApiContext = {
  flowAPI: ControllerFlowAPI;
  authorization: string;
};

export enum API_DOMAINS {
  MEMBER_AREA = '/_api/bookings-member-area',
}

export enum BusinessPropertyNames {
  CLIENT_CAN_CHANGE_TIMEZONE = 'clientCanChangeTimezone',
  DEFAULT_TIMEZONE_TYPE = 'defaultTimezone',
}
