import { ActionsContext } from '../../../../core/actionsContext/contextFactory';
import { BusinessInfo } from '../../../../types';
import { mapResponseToBusinessInfo } from './business-info-mapper';

export const getBusinessInfo = async (
  context: ActionsContext,
): Promise<BusinessInfo> => {
  const { api } = context;
  const businessInfo = await api.getBusinessInfo();

  return mapResponseToBusinessInfo(businessInfo);
};
