import { Bookings } from '../Bookings';
import { EnvironmentModes } from '../../api/implementations/environmentModes';
import { BusinessInfo } from '../BusinessInfo';
import { UserInfo } from '../Userinfo';
import { SelectedTimezone } from '../SelectedTimezone';
import { SelectedTab } from '../SelectedTab';

export type ApplicationState = {
  status: ApplicationStatus;
  environmentModes: EnvironmentModes;
  selectedTab: SelectedTab;
  upcomingData?: Bookings;
  historyData?: Bookings;
  businessInfo?: BusinessInfo;
  userInfo?: UserInfo;
  selectedTimezone?: SelectedTimezone;
};

export const enum ApplicationStatus {
  IDLE = 'idle',
  INITIALIZING = 'initializing',
}

export enum BusinessTimezoneTypes {
  BUSINESS = 'business',
  USER = 'client',
}
