import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BOOKINGS_APP_DEF_ID } from '../consts/applicationIdentities';
import { ApiContext } from './types';
import { getUpcoming } from './implementations/getUpcoming';
import { getHistory } from './implementations/getHistory';
import { CancelSingleArgs, cancelSingle } from './implementations/cancelSingle';
import { CancelGroupArgs, cancelGroup } from './implementations/cancelGroup';
import { Bookings } from '../types';
import { isSSR } from './implementations/isSSR';
import { GetAggregatedInfoResponse } from '@wix/ambassador-bookings-catalog-v1-business-info/types';
import { getBusinessInfo } from './implementations/getBusinessInfo';
import {
  ListResourcesByTagNameArgs,
  listResourcesByTagName,
} from './implementations/listResourcesByTagName';
import { getEnvironmentModes } from './implementations/environmentModes';

export class Api {
  private readonly flowAPI: ControllerFlowAPI;

  constructor({ flowAPI }: { flowAPI: ControllerFlowAPI }) {
    this.flowAPI = flowAPI;
  }

  private getApiContext(): ApiContext {
    return {
      flowAPI: this.flowAPI,
      authorization: this.getAuthorization(),
    };
  }

  private getAuthorization() {
    const { controllerConfig } = this.flowAPI;
    return (
      (controllerConfig.wixCodeApi.site.getAppToken
        ? controllerConfig.wixCodeApi.site.getAppToken(BOOKINGS_APP_DEF_ID)
        : undefined) || controllerConfig.appParams.instance
    );
  }

  async getUpcoming(): Promise<Bookings> {
    return getUpcoming({
      ...this.getApiContext(),
    });
  }

  async getHistory(): Promise<Bookings> {
    return getHistory({
      ...this.getApiContext(),
    });
  }

  async cancelSingle(args: CancelSingleArgs): Promise<number> {
    return cancelSingle({
      ...args,
      ...this.getApiContext(),
    });
  }

  async cancelGroup(args: CancelGroupArgs): Promise<number> {
    return cancelGroup({
      ...args,
      ...this.getApiContext(),
    });
  }

  async getBusinessInfo(): Promise<GetAggregatedInfoResponse> {
    return getBusinessInfo(this.getApiContext());
  }

  async listResourcesByTagName(args: ListResourcesByTagNameArgs) {
    return listResourcesByTagName({
      ...args,
      ...this.getApiContext(),
    });
  }

  isSSR() {
    return isSSR(this.getApiContext());
  }

  getEnvironmentModes() {
    return getEnvironmentModes({
      ...this.getApiContext(),
    });
  }
}
