import { API_DOMAINS, ApiContext } from '../../types';

export interface CancelGroupArgs {
  groupId: any;
}

export const cancelGroup = async ({
  groupId,
  flowAPI,
  authorization,
}: CancelGroupArgs & ApiContext): Promise<number> => {
  const { httpClient } = flowAPI;
  return (
    await httpClient.delete(
      `${API_DOMAINS.MEMBER_AREA}/site-member/group/${groupId}`,
      {
        headers: {
          Authorization: authorization,
        },
      },
    )
  ).status;
};
