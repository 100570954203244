import { BookingDTO, Bookings, OfferingDTO } from '../../../types';

export const bookingsMapper = (bookings: Bookings): Bookings => ({
  ...bookings,
  groups: bookings.groups.map((group) => ({
    ...group,
    bookings: group.bookings
      .map((booking) =>
        mapOfferingToBooking(bookings.relatedOfferings, booking),
      )
      .sort(sortBookingsByDate),
  })),
});

export const mapOfferingToBooking = (
  relatedOfferings: OfferingDTO[],
  booking: BookingDTO,
): BookingDTO => ({
  ...booking,
  offering: relatedOfferings.find(
    (offering) => offering.id === booking.offeringId,
  )!,
});

export const sortBookingsByDate = (a: BookingDTO, b: BookingDTO): number =>
  a.start - b.start;
