import { Bookings } from '../../../types';
import { API_DOMAINS, ApiContext } from '../../types';

export const getHistory = async ({
  flowAPI,
  authorization,
}: ApiContext): Promise<Bookings> => {
  const { httpClient } = flowAPI;

  return (
    await httpClient.get<Bookings>(
      `${API_DOMAINS.MEMBER_AREA}/member-area/bookings/history`,
      {
        headers: {
          Authorization: authorization,
        },
      },
    )
  ).data;
};
