import { ActionFactoryParams } from '../core/ControlledComponent/types';
import { ApplicationState } from '../types';
import { ActionsContext } from '../core/actionsContext/contextFactory';
import { createGetUpcomingAction, GetUpcoming } from './getUpcoming';
import {
  InitializeWidget,
  createInitializeWidgetAction,
} from './initializeWidget';
import { createGetHistoryAction, GetHistory } from './getHistory';
import {
  createSetSelectedTimezoneAction,
  SetSelectedTimezone,
} from './setSelectedTimezone';
import { createSetSelectedTabAction, SetSelectedTab } from './setSelectedTab';
import {
  ClickOnEmptyStateButton,
  createClickOnEmptyStateButtonAction,
} from './clickOnEmptyStateButton';

export type CreateActionParams = ActionFactoryParams<
  ApplicationState,
  ActionsContext
>;

export type ControllerActions = {
  initializeWidget: InitializeWidget;
  getUpcoming: GetUpcoming;
  getHistory: GetHistory;
  setSelectedTimezone: SetSelectedTimezone;
  setSelectedTab: SetSelectedTab;
  clickOnEmptyStateButton: ClickOnEmptyStateButton;
};

export const createActions = (
  actionFactoryParams: ActionFactoryParams<ApplicationState, ActionsContext>,
): ControllerActions => {
  const actions = {
    initializeWidget: createInitializeWidgetAction({ ...actionFactoryParams }),
    getUpcoming: createGetUpcomingAction({ ...actionFactoryParams }),
    getHistory: createGetHistoryAction({ ...actionFactoryParams }),
    setSelectedTimezone: createSetSelectedTimezoneAction({
      ...actionFactoryParams,
    }),
    setSelectedTab: createSetSelectedTabAction({ ...actionFactoryParams }),
    clickOnEmptyStateButton: createClickOnEmptyStateButtonAction({
      ...actionFactoryParams,
    }),
  };

  return actions;
};
