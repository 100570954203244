import { bookingsMapper } from '../../utils/mappers/bookings-mapper/bookings-mapper';
import { CreateActionParams } from '../actions';

export type GetHistory = () => void;

export function createGetHistoryAction({
  context,
  getControllerState,
}: CreateActionParams): GetHistory {
  return async () => {
    const [, setState] = getControllerState();
    const { api } = context;

    const historyData = bookingsMapper(await api.getHistory());

    setState({ historyData });
  };
}
