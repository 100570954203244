import { AppDefIds, SectionIds } from '../../types';
import { CreateActionParams } from '../actions';

export type ClickOnEmptyStateButton = () => void;

export function createClickOnEmptyStateButtonAction({
  context,
}: CreateActionParams): ClickOnEmptyStateButton {
  return async () => {
    const { flowAPI } = context;

    const { wixCodeApi } = flowAPI.controllerConfig;

    try {
      const serviceListSection = await wixCodeApi.site.getSectionUrl({
        sectionId: SectionIds.SERVICE_LIST_PAGE_ID,
        appDefinitionId: AppDefIds.Bookings,
      });
      if (serviceListSection.url) {
        wixCodeApi.location.to?.(serviceListSection.url);
      } else {
        wixCodeApi.location.to?.('/');
      }
    } catch {
      wixCodeApi.location.to?.('/');
    }
  };
}
