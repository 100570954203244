import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import {
  createMembersAreaWidgetPluginService,
  MembersAreaEvent,
  MembersAreaHostEvent,
  createWidgetPluginExports,
} from '@wix/members-area-widget-plugin-lib/viewer';
import { createControlledComponent } from '../../core/ControlledComponent';
import { ApplicationState, ApplicationStatus, TabTypes } from '../../types';
import {
  ActionsContext,
  createActionsContext,
} from '../../core/actionsContext/contextFactory';
import { ControllerActions, createActions } from '../../actions/actions';

export const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const widgetPluginService = createMembersAreaWidgetPluginService();

  const registerWidgetPluginHostListeners = () => {
    widgetPluginService.onMembersAreaEvent((event: MembersAreaHostEvent) => {
      switch (event.type) {
        case MembersAreaEvent.MemberChanged:
          return initialize();
      }
    });
  };

  const initialize = async () => {
    const context = createActionsContext({ flowAPI });

    const { onStateChange } = await createControlledComponent<
      ApplicationState,
      ControllerActions,
      ActionsContext
    >({
      context,
      actionsFactory: createActions,
      initialState: {
        status: ApplicationStatus.INITIALIZING,
        selectedTab: { type: TabTypes.UPCOMING },
        environmentModes: context.api.getEnvironmentModes(),
      },
    });

    if (!context.api.isSSR()) {
      onStateChange((state) => {
        context?.biLogger?.update(state);
      });
    }
  };

  return {
    async pageReady() {
      registerWidgetPluginHostListeners();
      await initialize();
    },
    exports: () => {
      return createWidgetPluginExports(widgetPluginService, initialize);
    },
  };
};

export default createController;
